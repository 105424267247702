<template>
  <div id="app">
    <img class="et-website--logo" alt="website logo" src="./assets/logo_Evisa_large.svg">
    <MainPage msg="Website is currently under development"/>
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue';

export default {
  name: 'App',
  components: {
    MainPage
  }
}
</script>

<style>
#app,
footer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

img.et-website--logo {
  width: 20%;
}
</style>
