<template>
  <div class="et-website-main">
    <h1>{{ msg }}</h1>
    <p>
      <a
        href="https://evisaterziu.com/cv/cv.html"
      >View my CV</a>
    </p>
    <p>
      <b>Linkedin: </b>
      <a href="https://www.linkedin.com/in/evisaterziu/">https://www.linkedin.com/in/evisaterziu/</a>
    </p>
    <p>
      <b>Github: </b>
      <a href="https://github.com/evisat">https://github.com/evisat</a>
    </p>
    <p>
      <b>University Dissertation Project: </b>
      <a
        href="https://evisat.github.io/6COSC006W-data-vis/dist/index.html"
      >https://evisat.github.io/6COSC006W-data-vis/dist/index.html</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  props: {
    msg: String,
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
